<template>
    <div style="width: 100%; margin-top:5vh; align-items: center; text-align: center;">
        <!-- <img src="../../assets/images/xianmian.png" margin="0 auto" width="100%"> -->
        加载中,请稍等...
    </div>
</template>
<script>
// import promise from 'es6-promise';
// promise.polyfill()
import axios from 'axios';
import base from '../../api/base';

export default {
    created(){
        // this.getUrlKey() //v1.1.5版本用
        this.getAll()
        // this.routPag113()
    },

    methods: {
        // /* 获取地址栏url参数 */
        // getUrlKey() {
        //     var url = window.location.href; //获取当前url
        //     var dz_url = url.split("#")[0]; //获取#/之前的字符串
        //     var cs = dz_url.split("?")[1]; //获取?之后的参数字符串
        //     var cs_arr = cs.split("&"); //参数字符串分割为数组
        //     var cs = {};

        //     for (var i = 0; i < cs_arr.length; i++) {
        //         //遍历数组，拿到json对象
        //         cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
        //     }

        //     this.urlId = cs.orderId;
        // },

        // getUrlKey(){
            
        //     this.routPag();
        // },

        getAll(){
            // http://jnj.evoka.com.cn /#/configuration
            // http://jnj.evoka.com.cn /#/configuration?user_telephone=电话号码
            // http://127.0.0.1:8081/#/configuration?user_telephone=13524181542&app_id=2
            var url = window.location.href;
            if(url.indexOf("?") != -1){
                var cs = url.split("?")[1];
                var cs_a_0_arr = cs.split("&")[0]
                var cs_0_arr = cs_a_0_arr.split("=")

                var cs_a_1_arr = cs.split("&")[1]
                var cs_1_arr = cs_a_1_arr.split("=")

                localStorage.setItem('get_app_url_user_phone', cs_0_arr[1])
                // console.log(cs_0_arr, cs_1_arr)
                this.routPag115(cs_0_arr[1], cs_1_arr[1]);
            }else{
                this.routPag113()  //v1.1.3版本用
            }


            // var url = window.location.href;
            // var cs = url.split("?")[1];
            // var cs_arr = cs.split("=")
            // localStorage.setItem('get_app_url_user_phone', cs_arr[1])
            // if(localStorage.getItem('get_app_url_user_phone')){
            //     console.log('!!!!!!!!!!!!!!!!!!!!!!')
            //     console.log(localStorage.getItem('get_app_url_user_phone'))
            //     this.routPag115();
            // }else{
            //     console.log('@@@@@@@@@@@@@@@@@@@@@')
            //     this.routPag113()  //v1.1.3版本用
            // }
        },

        routPag115(userPhone, appid){
            // 判断新用户是否已购买一天VIP，是否已购买过vip
            // 1、判断是否只有一个订单，并且订单只有一天vip，条件满足则展示新用户专享（1元7天VIP），否则隐藏新用户专享
            this.GetIsNewUserAndOrderStatus(userPhone, appid)
            // window.location.href = base.AppLocationUrl115 + '&phones=' + e;
        },

        routPag113(){
            window.location.href = base.AppLocationUrl;
        },

        //获取新用户状态，以及所属订单状态
        GetIsNewUserAndOrderStatus(userPhone, appid){
            let params = {
                phone:userPhone
            }
            
            this.postRequest('/api/is_new_user_and_order/', params).then(res => {
                console.log('-------------获取新用户状态，以及所属订单状态------------')
                console.log(res.data)
                console.log(appid)

                if(appid == 1){
                    top.location.href = base.AppLocationUrl115 + '&phones=' + res.data.Eesult.phone + '&init_view_is_show=' + res.data.Eesult.init_view_is_show + '&app_id=' + appid;
                }else{
                    top.location.href = base.AppLocationUrl150_2 + '&phones=' + res.data.Eesult.phone + '&init_view_is_show=' + res.data.Eesult.init_view_is_show + '&app_id=' + appid;
                }
                // if(appid == 2){
                //     top.location.href = base.AppLocationUrl150_2 + '&phones=' + res.data.Eesult.phone + '&init_view_is_show=' + res.data.Eesult.init_view_is_show;
                // }
                // if(appid == 3){
                //     top.location.href = base.AppLocationUrl150_3 + '&phones=' + res.data.Eesult.phone + '&init_view_is_show=' + res.data.Eesult.init_view_is_show;
                // }
                // if(appid == 4){
                //     top.location.href = base.AppLocationUrl150_4 + '&phones=' + res.data.Eesult.phone + '&init_view_is_show=' + res.data.Eesult.init_view_is_show;
                // }
                // if(appid == 5){
                //     top.location.href = base.AppLocationUrl150_5 + '&phones=' + res.data.Eesult.phone + '&init_view_is_show=' + res.data.Eesult.init_view_is_show;
                // }
                
                

                //top.window.location = base.AppLocationUrl115 + '&phones=' + res.data.Eesult.phone + '&init_view_is_show=' + res.data.Eesult.init_view_is_show;
            })

            // console.log("XXX", query)
            // let quesa = axios.get('http://a7bb4b9.cpolar.cn/api/is_new_user_and_order/?phone='+query).then(res => {     // 获取public下的buildmenu.json文件数据
            //     console.log('-------------获取新用户状态，以及所属订单状态------------')
            //     console.log(res)
                // let myDate = new Date();
                // for(var i=0; i < res.data.length; i++){
                //     if(res.data[i]['mechanism_name'] == query){
                //     console.log(res.data[i]['list'])
                //     this.vip_deadline = this.dateAddDays(myDate.toLocaleDateString(), res.data[i]['list'][0]['price_name'])
                //     this.resData = res.data[i]['list']
                //     }
                // }
                // let datas = {
                //     "code": 2000,
                //     "data":{
                //         "data":res.data,
                //         "limit": 10,
                //         "page": 1,
                //         "total": 10
                //     },
                //     "msg": "success"
                // }
                // return datas
            // })
            // return quesa
        },

        postRequest(url, params) {
            // alert(params.phone)
            
            return axios({
                method: 'post',
                url: `${base.ztbHost}${url}`,
                data: params,
            })
        }

        
    }

}
</script>
<style scoped>

</style>